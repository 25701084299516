import { Box, Button,Grid,IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { getSentiment, getSummary, tagConversation } from '../../services/conversations';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { formatKey } from '../../utils/stringFunctions';


function ConversationInfo({handleInfoToggle,conversation}) {
  const authUser = useAuthUser()
  const [user,setUser]=useState(authUser?.user??{})
  const [instanceId,setInstanceId]=useState(authUser?.instanceId??"")
  const [conversationSummary,setConversationSummary]=useState(conversation?.variables?.conversationSummary??"")
  const [customerSentiment,setCustomerSentiment]=useState(conversation?.variables?.customerSentiment??"")
  const [conversationTags,setConversationTags]=useState(conversation?.variables?.conversationTags??[])
  const handleGetSummary=async()=>{  
      try{
        const response=await getSummary(instanceId,{conversationId:conversation.conversationId,channel:conversation.channel})
        if(response.status===200){
          setConversationSummary(response.data.summary??"")
        }
      }
      catch(e){
        console.log(e)
      }
  }
  const handleGetSentiment=async()=>{  
    try{
      const response=await getSentiment(instanceId,{conversationId:conversation.conversationId,channel:conversation.channel})
      if(response.status===200){
        setCustomerSentiment(response.data.sentiment??"")
      }
    }
    catch(e){
      console.log(e)
    }
  }
  const handleGetTags=async()=>{  
    try{
      const response=await tagConversation(instanceId,{conversationId:conversation.conversationId,channel:conversation.channel})
      if(response.status===200){
        setConversationTags(response.data.tags??[])
      }
    }
    catch(e){
      console.log(e)
    }
  }
  const customer = conversation.variables?.customer;
  const ticket =conversation.variables?.ticket;

  return (
    <Grid container direction="row" sx={{height: '100%',borderLeft:'1px solid #F2F2F2'}}>
        <Grid item container direction="column"  sx={{ maxHeight: '100%', overflowY: 'auto' }}>
            <Box sx={{position: 'sticky', top: 0, minHeight: `10vh`,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',pl:2,pr:2,borderBottom:'1px solid #F2F2F2'}}>
              <IconButton sx={{ml:'auto'}} onClick={handleInfoToggle}>
                <CloseRoundedIcon/>
              </IconButton>
            </Box>
            <Box sx={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto', zIndex: 1}}>
                <Box
                    sx={{ 
                        overflowY: 'auto',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        scrollbarWidth: "none", // Firefox
                        "msOverflowStyle": "none", // IE and Edge
                        "&::-webkit-scrollbar": {
                            width: "0.4em", // Adjust as needed
                        },
                        "&::-webkit-scrollbar-track": {
                            background: "transparent", // Make the track invisible
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "transparent", // Make the thumb (scrollbar) invisible
                        },
                        p:2
                    }}
                >
                  {customer &&  <Typography sx={{fontFamily:'DM Sans Medium',fontSize:16,mb:2}}>Customer Information</Typography>}
                  <Grid container direction="row" spacing={1}>
                    {customer && Object.keys(customer).map((key) => (
                        <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} >
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{display:'inline-flex'}}>
                              <Typography sx={{fontFamily:'DM Sans Medium',color:'#68677F'}}>
                                {formatKey(key)}
                              </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{display:'flex',flexDirection:'column',wordWrap:'break-word'}}>
                              <Typography sx={{fontFamily:'DM Sans Light',color:'#68677F',}}>
                                {customer[key]}
                              </Typography>
                          </Grid>
                        </Grid>
                    ))}
                  </Grid>
                  {ticket &&  <Typography sx={{fontFamily:'DM Sans Medium',fontSize:16,mb:2}}>Ticket Information</Typography>}
                  <Grid container direction="row" spacing={1}>
                    {ticket && Object.keys(ticket).map((key) => (
                        <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} >
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} sx={{display:'inline-flex'}}>
                              <Typography sx={{fontFamily:'DM Sans Medium',color:'#68677F'}}>
                                {formatKey(key)}
                              </Typography>
                          </Grid>
                          <Grid item xs={8} sm={8} md={8} lg={8} xl={8} sx={{display:'flex',flexDirection:'column',wordWrap:'break-word'}}>
                              <Typography sx={{fontFamily:'DM Sans Light',color:'#68677F',}}>
                                {ticket[key]}
                              </Typography>
                          </Grid>
                        </Grid>
                    ))}
                  </Grid>
                  <Typography sx={{fontSize:'13px'}}>{conversationSummary}</Typography>
                  <Typography>{customerSentiment}</Typography>
                  <Typography>{conversationTags}</Typography>
                </Box>
            </Box>
            {/* <Box sx={{ position: 'sticky', mt: 'auto', minHeight: `10vh`,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width: '100%'}}>
                <Button onClick={()=>{handleGetSummary()}} disabled>
                  Get Summary
                </Button>
                <Button onClick={()=>{handleGetSentiment()}} disabled>
                  Get Sentiment
                </Button>
                <Button onClick={()=>{handleGetTags()}} disabled>
                  Get Tags
                </Button>
            </Box> */}
        </Grid>
    </Grid>
  )
}

export default ConversationInfo