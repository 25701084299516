import axiosInstance from '../auth/axiosConfig.js'


export async function getRooms(status="active",startAfterDocument=null){
    try{
        const response=await axiosInstance.post(`/sharedInbox/rooms`,{status,startAfterDocument})
        return response
    }catch(e){
        console.log("Exception In Conversation Service",e)
        return []
    }
}

export async function getEvents(roomId,startAfterDocument=null){
    try{
        const response=await axiosInstance.post(`/sharedInbox/events`,{roomId,startAfterDocument})
        return response
    }catch(e){
        console.log("Exception In Conversation Service",e)
        return []
    }
}


export async function getParticipants(roomId){
    try{
        const response=await axiosInstance.post(`/sharedInbox/participants`,{roomId})
        return response
    }catch(e){
        console.log("Exception In Conversation Service",e)
        return []
    }
}


export async function getAgents(){
    try{
        const response=await axiosInstance.post(`/sharedInbox/agents`,{})
        return response
    }catch(e){
        console.log("Exception In Conversation Service",e)
        return []
    }
}


export async function getNotifications(){
    try{
        const response=await axiosInstance.post(`/sharedInbox/notifications`,{})
        return response
    }catch(e){
        console.log("Exception In Conversation Service",e)
        return []
    }
}


export async function deleteNotifications(roomId){
    try{
        const response=await axiosInstance.post('/sharedInbox/deleteNotifications',{roomId:roomId})
        return response
    }catch(e){
        console.log("Exception In Conversation Service",e)
        return []
    }
}


export async function updateConversationStatus(conversation,status){
    try{
        const response=await axiosInstance.post(`/sharedInbox/updateConversationStatus`,{conversation,status})
        return response
    }catch(e){
        console.log("Exception In Conversation Service",e)
        return []
    }
}

export async function updateRoomAgentsAssignment(data){
    try{
        const response=await axiosInstance.post(`/sharedInbox/updateRoomAgentsAssignment`,data)
        return response
    }catch(e){
        console.log("Exception In Conversation Service",e)
        return []
    }
}

export async function getMediaURL(data){
    try{
        const response=await axiosInstance.post(`/sharedInbox/mediaURL`,data)
        return response
    }catch(e){
        console.log("Exception In Conversation Service",e)
        return []
    }
}


export async function subscribeToFCMTopic(data){
    try{
        const response=await axiosInstance.post(`/sharedInbox/subscribeToFCMTopic`,data)
        return response
    }catch(e){
        console.log("Exception In Conversation Service",e)
        return []
    }
}



export async function getSummary(conversation){
    try{
        const response=await axiosInstance.post(`/sharedInbox/conversationSummary`,{conversation:conversation})
        return response
    }catch(e){
        console.log("Exception In Conversation Service",e)
        return []
    }
}

export async function getSentiment(conversation){
    try{
        const response=await axiosInstance.post(`/sharedInbox/analyseSentiment`,{conversation:conversation})
        return response
    }catch(e){
        console.log("Exception In Conversation Service",e)
        return []
    }
}


export async function tagConversation(conversation){
    try{
        const response=await axiosInstance.post(`/sharedInbox/tagConversation`,{conversation:conversation})
        return response
    }catch(e){
        console.log("Exception In Conversation Service",e)
        return []
    }
}