import React from 'react'
import { Avatar, Grid ,Box,FormControl,FormLabel,TextField,Typography,Button} from '@mui/material';
import botIcon from '../../assets/botIcon.jpg';
import ReactMarkdown from 'react-markdown';
import { formatDate } from '../../utils/dateTimeFunctions';

function AppNotificationMessage({index,event,variables}) {
    const imageHref = (src) => {
        window.open(src, '_blank');
    }
    const components = {
        img: ({ node, ...props }) => (
            <></>
        ),
        ol: ({ node, ...props }) => (
            <ol style={{ fontSize: '13px', maxWidth: '100%',padding:'10px',marginTop:'10px'}}> 
            {props.children}
            </ol>
        ),
        li: ({ node, ...props }) => (
            <li sx={{ fontSize: '13px', maxWidth: '100%'}}>
               {props.children}
            </li>
        ),
        a: ({ node, ...props }) => (
            <a href={props.href} onClick={(e)=>{e.preventDefault();imageHref(props.href)}} target="_blank" rel="noopener noreferrer">
              {props.children}
            </a>
        ),
        p:({ node, ...props }) => (
            <Typography sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }} >
               {props.children}
            </Typography>
        ),
    };
    
    return (
    <>
            <Grid item key={index} xs={12} sm={12} md={12} lg={12} xl={12}
                aria-label="botMessage"
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    mt:1.5,mb:1.5
                }}
            >
                <Box sx={{display:'flex',flexDirection:'column',mr:1.5,ml:1,mt:0.5}}>
                    <Avatar src={botIcon} sx={{ borderRadius: '50%', height: '30px', width: '30px'}} />
                </Box>
                <Box
                    sx={{
                        width: 'fit-content',
                        maxWidth: '100%',
                        overflowWrap: 'break-word',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{display:'inline-flex',justifyContent:'flex-end',alignItems:'center',gap:1}}>
                        <Typography 
                            sx={{
                                fontFamily:'DM Sans Medium',
                                fontSize:'15px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}>
                            Mercuri App
                        </Typography>
                        <Typography 
                            sx={{
                                fontFamily:'DM Sans Light',
                                fontSize:'12px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                            >
                            {formatDate(event.timestamp)}
                        </Typography>
                    </Box>
                    
                    <Box sx={{mt:0.5, maxWidth: '65%'}}>
                         <ReactMarkdown components={components}>{event.text}</ReactMarkdown>
                    </Box>
                   
                </Box>
            </Grid>
    </>
  )
}

export default AppNotificationMessage