export function capitalizeWords(str) {
    try{
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }
    catch(e){
        console.log('Error Capitalizing Words',e)
    }
}


export const formatKey = (key) => {
    const keyMappings = {
      firstName: 'First Name',
      lastName: 'Last Name',
      contactId:'Customer Id',
      phoneNumber:'Phone Number',
      email:'Email ID',
      waId:'Whatsapp Account Id',
      profileName:'Whatsapp Profile Name',
      message:'Message'    
    };
    return keyMappings[key] || key;
  };

function isDark(color) {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness < 128;
}


export function stringToLightColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = ((hash >> (i * 8)) & 0xff) % 150 + 100; // Restricting the range to lighter colors
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}


export function stringAvatar(name) {
    if (typeof name !== 'string' || name.trim() === '') {
      return {
        sx: {
          ml: -1,
          cursor: 'pointer',
          borderRadius: '50%',
          height: '40px',
          width: '40px',
          fontFamily: 'DM Sans Medium',
          fontSize: '14px',
          letterSpacing: 1,
          bgcolor: '#CCCCCC', 
          color: '#000000',  
        },
        children: '',
      };
    }
    const names = name.trim().split(' ');
    const initials = names.length > 1
      ? `${names[0][0].toUpperCase()}${names[1][0].toUpperCase()}`
      : names[0][0].toUpperCase();
    
    const backgroundColor = stringToLightColor(name); 
    const color = isDark(backgroundColor) ? '#FFFFFF' : '#000000'; 
    return {
      sx: {
        ml: -1,
        cursor: 'pointer',
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        fontFamily: 'DM Sans Medium',
        fontSize: '14px',
        letterSpacing: 1,
        bgcolor: backgroundColor,
        color: color,
      },
      children: initials,
    };
}


export function uuidToBase64(uuid) {
    const cleanedUuid = uuid.replace(/-/g, '');
    const bytes = [];
    for (let i = 0; i < 16; i++) {
        bytes.push(parseInt(cleanedUuid.substr(i * 2, 2), 16));
    }
    const base64 = btoa(String.fromCharCode(...bytes));
    return base64;
}


export function truncateText(text, startWords = 5, endWords = 5) {
  if (!text) return '';

  const words = text.split(' ');

  if (words.length <= startWords + endWords) {
    return text; // No need to truncate if it's short enough
  }

  const start = words.slice(0, startWords).join(' ');
  const end = words.slice(-endWords).join(' ');

  return `${start} ... ${end}`;
}
