import React, { useState, useRef, useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import ConversationHeader from "./ConversationHeader.jsx";
import ConversationFooter from './ConversationFooter.jsx';
import ConversationInfo from './ConversationInfo.jsx';
import { useMediaQuery, useTheme } from '@mui/material';
import { CircularProgress } from '@mui/material';

import AppNotificationMessage from '../Common/AppNotificationMessage.jsx';
import UserMessage from '../Common/UserMessage.jsx';
import ChatBotMessage from '../Common/ChatBotMessage.jsx';
import CustomerMessage from '../Common/CustomerMessage.jsx';
import OtherUserMessage from '../Common/OtherUserMessage.jsx';
import { formatDay } from '../../utils/dateTimeFunctions.js';

function ConversationWindow({
  user,
  agents,
  setSelectedConversation,
  conversation,
  eventsCount,
  events,
  message,
  handleSend,
  fetchEvents,
  changeConversationStatus,
  updateRoomAgents,
  setMobileConversationSelected,
  showHeader,
  headerHeight,
  showFooter,
  footerHeight
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [showInfo, setShowInfo] = useState(isMobile ? false : true);
  const handleInfoToggle = () => {
    setShowInfo((prev) => !prev);
  };

  const getAgent = (userId) => {
    return agents.find(agent => agent.userId === userId);
  };

  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  
  const fetchMoreData = async () => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      await fetchEvents(events[0]?.documentId ?? null);
      setHasMore(events.length < eventsCount);  
    } catch (error) {
      console.error('Error fetching events data:', error);
    } finally {
      setLoading(false);
    }
  };

  const chatHeaderRef = useRef(null);
  const chatBoxRef = useRef(null);
  const chatFooterRef = useRef(null);

  const [scrollHeight, setScrollHeight] = useState(500);
  const [scrollTop, setScrollTop] = useState(0);
  const [isAtBottom, setIsAtBottom] = useState(true);

  useEffect(() => {
    if (chatHeaderRef.current && chatFooterRef.current) {
      const conversationHeaderHeight = chatHeaderRef.current.offsetHeight;
      const conversationFooterHeight = chatFooterRef.current.offsetHeight;
      const containerHeight = window.innerHeight - (showHeader ? headerHeight : 0) - (showFooter ? footerHeight : 0);
      const newHeight = containerHeight - conversationHeaderHeight - conversationFooterHeight;
      setScrollHeight(newHeight);
    }
  }, [chatHeaderRef.current, chatFooterRef.current, window.innerHeight, showHeader, showFooter, headerHeight, footerHeight]);

  useEffect(() => {
    if (chatBoxRef.current) {
      const chatBox = chatBoxRef.current;
      const previousScrollTop = chatBox.scrollTop;

      if (isAtBottom) {
        chatBox.scrollTop = chatBox.scrollHeight;
      } else {
        chatBox.scrollTop = previousScrollTop;
      }
    }
  }, [events, isAtBottom]);

  const handleScroll = () => {
    if (chatBoxRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatBoxRef.current;

      if (scrollHeight - scrollTop === clientHeight) {
        setIsAtBottom(true);
      } else {
        setIsAtBottom(false);
      }

      if (scrollTop === 0 && hasMore) {
        fetchMoreData();
      }

      setScrollTop(scrollTop);
    }
  };

  let lastMessageDate = null;
  return (
    <React.Fragment>
      {conversation && Object.keys(conversation).length > 0 && (
        <Grid container direction="row" style={{ height: "100%" }}>
          <Grid
            item
            container
            direction="column"
            xs={!isMobile && showInfo ? 8 : 12}
            sm={!isMobile && showInfo ? 6 : 12}
            md={!isMobile && showInfo ? 7 : 12}
            lg={!isMobile && showInfo ? 8 : 12}
            xl={!isMobile && showInfo ? 8 : 12}
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box
              ref={chatHeaderRef}
              sx={{
                position: "sticky",
                top: 0,
                minHeight: `10vh`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ConversationHeader
                setSelectedConversation={setSelectedConversation}
                handleInfoToggle={handleInfoToggle}
                agents={agents}
                conversation={conversation}
                changeConversationStatus={changeConversationStatus}
                updateRoomAgents={updateRoomAgents}
                setMobileConversationSelected={setMobileConversationSelected}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <div
                ref={chatBoxRef}
                id='chatBox'
                onScroll={handleScroll}
                style={{
                  height: `${scrollHeight}px`,
                  overflow: 'auto',
                  position: "absolute",
                  padding:'10px',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  scrollbarWidth:  "none", // Firefox
                  msOverflowStyle: "none", // IE and Edge
                  "&::-webkit-scrollbar": {
                    width: "0.2em", 
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent", 
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#DF6437", 
                    borderRadius: "50px",
                  },
                }}
              >
                {events.map((event, index) => {
                  const messageDate = new Date(event.timestamp);
                  const shouldShowDate = !lastMessageDate || messageDate.toDateString() !== lastMessageDate.toDateString();
                  lastMessageDate = messageDate;
                  return (
                    <React.Fragment key={event.documentId}>
                      {shouldShowDate && (
                        <Grid item xs={12} sx={{ textAlign: 'center', my: 2 }}>
                          <Typography variant="caption" color="textSecondary">
                            {formatDay(messageDate)}
                          </Typography>
                        </Grid>
                      )}
                      {event.userId === user.userId && (
                        <UserMessage id={event.documentId} index={index} event={event} />
                      )}
                      {event.userId === 'mercuriCXAgent' && (
                        <ChatBotMessage id={event.documentId} index={index} event={event} variables={conversation.variables} />
                      )}
                      {event.userId === 'mercuriNotification' && (
                        <AppNotificationMessage id={event.documentId} index={index} event={event} variables={conversation.variables} />
                      )}
                      {event.userId === conversation.conversationId && (
                        <CustomerMessage id={event.documentId} index={index} event={event} variables={conversation.variables} />
                      )}
                      {event.userId !== user.userId && getAgent(event.userId) && (
                        <OtherUserMessage
                          id={event.documentId}
                          user={getAgent(event.userId)}
                          index={index}
                          event={event}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </Box>

            <Box
              ref={chatFooterRef}
              sx={{
                position: "sticky",
                bottom: 0,
                minHeight: `10vh`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <ConversationFooter
                conversation={conversation}
                message={message}
                handleSend={handleSend}
              />
            </Box>
          </Grid>
          {!isMobile && showInfo ? (
            <Grid
              item
              xs={4}
              sm={6}
              md={5}
              lg={4}
              xl={4}
              sx={{
                display: "flex",
                overflowY: "auto",
                border: "1px solid #F2F2F2",
              }}
            >
              <ConversationInfo
                handleInfoToggle={handleInfoToggle}
                conversation={conversation}
              />
            </Grid>
          ) : null}
        </Grid>
      )}
    </React.Fragment>
  );
}

export default ConversationWindow;
