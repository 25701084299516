import { Box,Typography } from '@mui/material'
import React from 'react'

function LocationMessage({event}) {
    const openMap = (latitude, longitude) => {
        const url = `https://www.google.com/maps/@${latitude},${longitude},15z`; // Zoom level can be adjusted
        window.open(url, '_blank');
    };
    return (
        <Box mt={1}>
            {event.location?.name && <Typography  sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>Name&nbsp;:&nbsp;{event.location.name}</Typography>}
            {event.location?.url && <Typography  sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>Website&nbsp;:&nbsp;<a href={event.location.url} target="_blank" style={{color: 'blue', cursor: 'pointer',textDecoration:'none'}}>{event.location.url}</a></Typography>}
            {event.location?.address && <Typography  sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>Address&nbsp;:&nbsp;{event.location.address}</Typography>}
            {event.location?.latitude && event.location?.longitude && (
                <Typography  sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>
                    Latitude&nbsp;:&nbsp;
                    <span
                        style={{ color: 'blue', cursor: 'pointer' }}
                        onClick={() => openMap(event.location.latitude, event.location.longitude)}
                    >
                        {event.location.latitude}
                    </span>
                    <br />
                    Longitude&nbsp;:&nbsp;
                    <span
                        style={{ color: 'blue', cursor: 'pointer'}}
                        onClick={() => openMap(event.location.latitude, event.location.longitude)}
                    >
                        {event.location.longitude}
                    </span>
                </Typography>
            )}
        </Box>
    )
}

export default LocationMessage