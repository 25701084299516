import React from 'react';
import {Box,TextField, FormControl,Grid, Stack, IconButton, Button} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import {useAppStore} from './AppStore';

function ConversationFooter({conversation,handleSend}){
    const { message, handleMessageChange,deleteMessage } = useAppStore();
    
    const handleCustomerMessageKeyDown = (e) => {
        const value=message[`${conversation.documentId}`]?.text
        if (e.key === 'Enter' && !e.shiftKey && value!==undefined && value.trim()!=="") {
            handleSendMessage(e)
        } else if (e.key === 'Enter' && e.shiftKey) {
            handleMessageChange(conversation.documentId,message[`${conversation.documentId}`]?.text.concat('\n')??"")
        }
    };

    const handleSendMessage= (e)=>{
        e.preventDefault();
        const sent=handleSend(conversation.documentId,message[`${conversation.documentId}`])
        if(sent){
            deleteMessage(conversation.documentId)
        }
    }

    return (
        <form onSubmit={handleSendMessage} style={{width:'100%'}}>
            <Grid container direction="row" spacing={2} sx={{pl:2,pr:2}}>
                <Grid item xs={10} sm={11} md={11} lg={11} xl={11}>
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            sx={{ minHeight:'40px',overflowY:'hidden' }}
                            value={message[`${conversation.documentId}`]?.text??""}
                            onKeyDown={(e)=>{handleCustomerMessageKeyDown(e)}}
                            onChange={(e)=>{handleMessageChange(conversation.documentId,e.target.value)}}
                            multiline
                            maxRows={8}
                            size='small'
                            type="text"
                            placeholder='Your Message'
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
                    <FormControl fullWidth>
                        <IconButton type="submit" color='secondary' disabled={message[conversation.documentId]?.text !== undefined && message[conversation.documentId]?.text !== ""?false:true} sx={{color:'#1C1D22',borderRadius:'5px',minHeight:'40px'}} onClick={handleSendMessage}><SendRoundedIcon/> </IconButton>
                    </FormControl>
                </Grid>
            </Grid>
        </form>
  )
}

export default ConversationFooter