import { createTheme} from "@mui/material";

let theme = createTheme({
    typography:{ 
        fontFamily: "DM Sans Regular",
        htmlFontSize: 16,
        fontSize: 14,
        button:{
          fontFamily: 'DM Sans Medium',
          fontWeight: 500,
          fontSize: '14px',
          textTransform: "capitalize",
          lineHeight: 1.75
        }
    },
    palette: {
      secondary: {
        main: '#DF6437',
      },
    },
});

export default theme;
