import React,{useState} from 'react'
import { useNavigate} from "react-router-dom";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { IconButton,Tooltip,Button,Dialog,DialogActions ,DialogContent,DialogContentText,DialogTitle,Slide} from '@mui/material';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import axiosInstance from "../../auth/axiosConfig";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function LogoutModal(props) {
  const navigate = useNavigate();

  const handleClose = () => {
    props.setModal(false);
  };

  const logout = async () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
          registration.unregister().then((boolean) => {
            console.log('Service worker unregistered:', boolean);
          });
        }
      }).catch((error) => {
        console.error('Service worker unregister failed:', error);
      });
    }
    const response = await axiosInstance.post("/sharedInbox/auth/logout",{fcmToken:props.fcmToken});
    return response
  };

  const signOut = useSignOut()

  const handleLogout=()=>{
      logout()
      signOut()
      navigate('/login')
  }
  
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="success" onClick={handleLogout}>
            Yes
          </Button>
          <Button variant="outlined" color="error" onClick={handleClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}




function LogoutButton({fcmToken}) {
    const [logout, setLogout] = useState(false);
    return (
      <React.Fragment>
        <Tooltip title="Logout">
          <IconButton onClick={()=>{setLogout(true)}}>
            <LogoutRoundedIcon />
          </IconButton>
        </Tooltip>
        <LogoutModal open={logout} setModal={setLogout} fcmToken={fcmToken} />
      </React.Fragment>
    )
}

export default LogoutButton