import React from 'react'
import { Box,Grid, TextField ,Button,Select,MenuItem} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';


function NavigationSearchFilter({ searchFilter, mobileView }) {
  return (
    <Grid container direction="row" rowSpacing={1} columnSpacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          color="secondary"
          fullWidth
          size="small"
          type="search"
          placeholder=" Search"
          InputProps={{
            startAdornment: (
              <SearchOutlinedIcon
                fontSize={mobileView && "small"}
                position="start"
                sx={{ color: "#130F26" }}
              />
            ),
            style: { borderRadius: mobileView && "12px", border: "none" },
          }}
          sx={{
            backgroundColor: mobileView && "#F3F4F7",
            borderRadius: "6px",
            border: "none",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: mobileView && "none",
              },
            },
          }}
          onChange={(e) => {
            searchFilter(e.target.value);
          }}
        />
      </Grid>
    </Grid>
  );
}

export default NavigationSearchFilter