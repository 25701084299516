import React,{useState} from 'react'
import Box from '@mui/material/Box';
import {Typography } from '@mui/material';
function NotificationBar({notificationsCount}) {
  return (
    <Box
      sx={{
        display:'inline-flex',
        flexDirection:'row',
        flexWrap:'wrap',
        justifyContent:'left',
        alignItems:'center',
        width:'100%'
      }}
    >
      <Typography sx={{fontFamily:'DM Sans Light',fontSize:'18px',fontWeight:600}}>Conversations</Typography>
      {notificationsCount>0 &&
        <Box sx={{ml:'auto',display:'inline-flex',gap:1}}>
            <Box sx={{ml:'auto',width:'35px',height:'35px',background:'#FFE6CB',borderRadius:'50%',color:'#000000',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
              <Typography sx={{fontSize:'13px',fontWeight:600}}>{notificationsCount > 99 ? `99+` :  `${notificationsCount}`}</Typography>
            </Box>
        </Box>
      }
    </Box>
  )
}

export default NotificationBar