import React, { createContext, useContext, useState } from 'react';

const ConversationsContext = createContext();

export function ConversationsContextProvider({ children }) {
    const [message, setMessage] = useState({});
    const [roomAgents, setRoomAgents] = useState({});
    const [eventsCount, setEventsCount] = useState({});
    const [events, setEvents] = useState({});

    const [notificationsCount,setNotificationsCount]=useState(0)
    const [notifications,setNotifications]=useState({})
    
    // Message functions
    const handleMessageChange = (roomId, value) => {
        setMessage(prevMessages => ({
            ...prevMessages,
            [roomId]: {
                ...prevMessages[roomId],
                type: 'text',
                text: value,
            }
        }));
    };

    const deleteMessage = (roomId) => {
        setMessage(prevMessages => {
            const updatedMessages = { ...prevMessages };
            if (updatedMessages[roomId]) {
                delete updatedMessages[roomId];
            }
            return updatedMessages;
        });
    };

    // Agent functions
    const setActualAgents = (roomId, value) => {
        setRoomAgents(prevRooms => ({
            ...prevRooms,
            [roomId]: {
                actualAgents: value,
                updatedAgents: prevRooms[roomId]?.updatedAgents ?? value,
            },
        }));
    };

    const setUpdatedAgents = (roomId, value) => {
        setRoomAgents(prevRooms => ({
            ...prevRooms,
            [roomId]: {
                actualAgents: prevRooms[roomId]?.actualAgents ?? [],
                updatedAgents: value,
            },
        }));
    };

    // Events functions
    const updateEventsCount = (roomId, newValue) => {
        setEventsCount(prevCount => {
            const updated={...prevCount}
            updated[roomId] = newValue
            return updated
        });
    };

    const updateEvents = (roomId, newEvents) => {
        setEvents(prevEvents => {
            const existingEvents = prevEvents[roomId] || [];
            const existingEventIds = new Set(existingEvents.map(event => event.documentId));
            const filteredNewEvents = newEvents.filter(event => !existingEventIds.has(event.documentId));
            const mergedEvents = [...filteredNewEvents, ...existingEvents];
            const sortedEvents = mergedEvents.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
            return {
                ...prevEvents,
                [roomId]: sortedEvents
            };
        });
    };

    const appendEvent = (roomId, newEvent) => {
        setEvents(prevEvents => {
            const existingEvents = prevEvents[roomId] || [];
            const existingEventIds = new Set(existingEvents.map(event => event.documentId));
            if (!existingEventIds.has(newEvent.documentId)) {
                const updatedEvents = [
                    ...existingEvents,
                    newEvent
                ].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
                return {
                    ...prevEvents,
                    [roomId]: updatedEvents
                };
            }
            return prevEvents;
        });
    };

    const contextValue = {
        message,
        handleMessageChange,
        deleteMessage,
        roomAgents,
        setActualAgents,
        setUpdatedAgents,
        eventsCount,
        updateEventsCount,
        events,
        updateEvents,
        appendEvent,
        notifications,
        setNotifications,
        notificationsCount,
        setNotificationsCount
    };

    return (
        <ConversationsContext.Provider value={contextValue}>
            {children}
        </ConversationsContext.Provider>
    );
}

export function useAppStore() {
    return useContext(ConversationsContext);
}
