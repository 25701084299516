import React,{useEffect, useState} from 'react';
import {Select,Menu,MenuItem,SwipeableDrawer,InputAdornment,Grid, Box,Typography,Stack, IconButton, Button, Avatar, ListItemIcon, ListItemText, OutlinedInput, Checkbox, ListItem, ListItemButton, Tooltip} from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import { useAppStore} from './AppStore.jsx';
import { hasArrayChanged,checkArrayItemsPresence} from '../../utils/arrayFunctions.js';
import { capitalizeWords, stringAvatar,uuidToBase64 } from '../../utils/stringFunctions.js';
import CircleIcon from '@mui/icons-material/Circle';
import PublishedWithChangesRoundedIcon from '@mui/icons-material/PublishedWithChangesRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";


function ConversationHeader({
  setSelectedConversation,
  handleInfoToggle,
  agents,
  conversation,
  changeConversationStatus,
  updateRoomAgents,
  setMobileConversationSelected,
}) {
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const statuses = [
    { value: "active", color: "green" },
    { value: "closed", color: "red" },
  ];

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const { roomAgents, setActualAgents, setUpdatedAgents } = useAppStore();
  const [showAssign, setShowAssign] = useState(false);

  const handleAgentSelectChange = (value) => {
    setUpdatedAgents(conversation.documentId, value);
    setShowAssign(
      hasArrayChanged(roomAgents[conversation.documentId]?.actualAgents, value)
    );
  };

  async function updateAgentRoomAssignment() {
    if (
      hasArrayChanged(
        roomAgents[conversation.documentId].actualAgents,
        roomAgents[conversation.documentId].updatedAgents
      )
    ) {
      const updatedAgents = checkArrayItemsPresence(
        roomAgents[conversation.documentId].actualAgents,
        roomAgents[conversation.documentId].updatedAgents
      );
      const response = await updateRoomAgents(
        conversation.documentId,
        updatedAgents
      );
      if (response === true) {
        setActualAgents(
          conversation.documentId,
          roomAgents[conversation.documentId].updatedAgents
        );
        setShowAssign(false);
      } else {
        setUpdatedAgents(
          conversation.documentId,
          roomAgents[conversation.documentId].actualAgents
        );
        setShowAssign(false);
      }
    }
  }
  return (
    isMobile? <Grid
    container
    direction="row"
    spacing={2}
    sx={{ p: 2, borderBottom: "1px solid #F2F2F2" }}
  >
    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          // backgroundColor: "red",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => {
              setSelectedConversation(null);
              setMobileConversationSelected &&
                setMobileConversationSelected(false);
            }}
            size="small"
            disableFocusRipple
            disableRipple
          >
            <KeyboardBackspaceOutlinedIcon sx={{ fontSize: "24px", mr: 1 }} />
          </IconButton>
          <Avatar
            sx={{ height: "60px", width: "60px" }}
            {...stringAvatar(
              (conversation.variables &&
              conversation.variables.customer &&
              conversation.variables.customer.firstName
                ? conversation.variables.customer.firstName + " "
                : "") +
                (conversation.variables &&
                conversation.variables.customer &&
                conversation.variables.customer.lastName
                  ? conversation.variables.customer.lastName
                  : "") || "Customer"
            )}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              alignItems: "left",
              pl: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: "DM Sans Medium",
                fontSize: 14,
                fontWeight: 500,
                textOverflow: "ellipsis",
              }}
            >
              {(() => {
                const firstName =
                  conversation.variables?.customer?.firstName || "";
                const lastName =
                  conversation.variables?.customer?.lastName || "";
                const customerName = `${firstName} ${lastName}`.trim();
                const profileName=conversation.variables?.customer?.profileName || "";
                if (customerName||profileName) {
                  return customerName||profileName;
                } 

                if (conversation.channel.toLowerCase() === "widget") {
                  return `${conversation.channel.toUpperCase()} ${uuidToBase64(
                    conversation.conversationId
                  )}`;
                } else {
                  return conversation.conversationId;
                }
              })()}
            </Typography>
          </Box>
        </Box>
        <Box>
          <IconButton onClick={handleDrawerToggle} sx={{ color: "#000000" }}>
            <PermIdentityOutlinedIcon />
          </IconButton>
          <IconButton onClick={handleMenuClick} sx={{ color: "#000000" }}>
            <MoreVertOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
      <SwipeableDrawer
        anchor="bottom"
        open={openDrawer}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            m:1,
            position: "absolute",
            minHeight: "30%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            overflowY: "auto",
            borderRadius: "30px 30px 0px 0px",
          },
        }}
      >
        {/* <Typography variant="h6" sx={{ p: 2 }}>
          Assign to:
        </Typography> */}
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Select
            multiple
            fullWidth
            size="small"
            value={roomAgents[conversation.documentId]?.updatedAgents ?? []}
            sx={{
              // backgroundColor: "red",
              borderRadius: 25,
            }}
            startAdornment={
              <InputAdornment position="start">
                <AccountCircleIcon
                  sx={{
                    color: "#DF6437",
                    fontSize: 30,
                  }}
                />
              </InputAdornment>
            }
            onChange={(e) => {
              handleAgentSelectChange(e.target.value);
            }}
            renderValue={(selected) => {
              const displayLimit = 2;
              const selectedNames = selected.map((userId) => {
                const agent = agents.find((agent) => agent.userId === userId);
                return agent
                  ? `${agent.accountInfo.firstName} ${agent.accountInfo.lastName}`
                  : "";
              });
              return (
                <span>
                  {selectedNames.slice(0, displayLimit).join(", ")}
                  {selectedNames.length > displayLimit
                    ? ` +${selectedNames.length - displayLimit} more`
                    : ""}
                </span>
              );
            }}
          >
            {agents.map((agent) => (
              <MenuItem value={agent.userId} key={agent.userId}>
                <Checkbox
                  checked={roomAgents[
                    conversation.documentId
                  ]?.updatedAgents.includes(agent.userId)}
                />
                <ListItemText primary={`${agent.accountInfo.firstName} ${agent.accountInfo.lastName}`}/>
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            sx={{
              mb: 1,
              color: "#FFFF",
              border: "1px solid #E9963F",
            }}
            onClick={updateAgentRoomAssignment}
          >
            Assign
          </Button>
        </Box>
      </SwipeableDrawer>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          "& .MuiPaper-root": {
            width: "200px",
          },
        }}
      >
        <Select
          size="small"
          sx={{
            minWidth: isSmallScreen ? "90%" : 150,
            maxWidth: isSmallScreen ? "90%" : 150,
            borderRadius: 25,
            overflow: "hidden",
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                background: "#FFFFFF",
              },
            },
          }}
          value={conversation.status}
          renderValue={(selected) => {
            const status = statuses.find(
              (status) => status.value === selected
            );
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <CircleIcon style={{ color: status.color, fontSize: 20 }} />
                <span style={{ marginLeft: 8 }}>
                  {capitalizeWords(status.value)}
                </span>
              </div>
            );
          }}
          onChange={(e) => {
            changeConversationStatus(conversation, e.target.value);
          }}
        >
          {statuses.map((status,index) => (
            <MenuItem value={status.value} key={index}>
              <ListItemIcon>
                <CircleIcon style={{ color: status.color, fontSize: 20 }} />
              </ListItemIcon>
              <ListItemText primary={capitalizeWords(status.value)} />
            </MenuItem>
          ))}
        </Select>
      </Menu>
    </Grid>
  </Grid>:

    <Grid
      container
      direction="row"
      spacing={2}
      sx={{ p: 2, borderBottom: "1px solid #F2F2F2" }}
    >
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                setSelectedConversation(null);
                setMobileConversationSelected &&
                  setMobileConversationSelected(false);
              }}
              size="small"
              disableFocusRipple
              disableRipple
            >
              <ArrowBackIosRoundedIcon sx={{ fontSize: "12px", mr: 1 }} />
            </IconButton>
            <Avatar
              {...stringAvatar(
                (conversation.variables &&
                conversation.variables.customer &&
                conversation.variables.customer.firstName
                  ? conversation.variables.customer.firstName + " "
                  : "") +
                  (conversation.variables &&
                  conversation.variables.customer &&
                  conversation.variables.customer.lastName
                    ? conversation.variables.customer.lastName
                    : "") || "Customer"
              )}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                alignItems: "left",
                pl: 2,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "DM Sans Medium",
                  fontSize: 14,
                  fontWeight: 500,
                  textOverflow: "ellipsis",
                }}
              >
                {(() => {
                  const firstName =
                    conversation.variables?.customer?.firstName || "";
                  const lastName =
                    conversation.variables?.customer?.lastName || "";
                  const customerName = `${firstName} ${lastName}`.trim();
                  const profileName=conversation.variables?.customer?.profileName || "";
                  if (customerName||profileName) {
                    return customerName||profileName;
                  } 

                  if (conversation.channel.toLowerCase() === "widget") {
                    return `${conversation.channel.toUpperCase()} ${uuidToBase64(
                      conversation.conversationId
                    )}`;
                  } else {
                    return conversation.conversationId;
                  }
                })()}
              </Typography>
            </Box>
          </Box>
          {isSmallScreen
            ? !isMobile && (
                <Box
                  sx={{
                    ml: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <IconButton onClick={handleInfoToggle}>
                    <MenuOpenRoundedIcon />
                  </IconButton>
                </Box>
              )
            : null}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Stack
          direction={isSmallScreen ? "column" : "row"}
          spacing={2}
          sx={{
            justifyContent: isSmallScreen ? "center" : "flex-end",
            alignItems: isSmallScreen ? "center" : "flex-end",
          }}
        >
          <Select
            multiple
            size="small"
            value={roomAgents[conversation.documentId]?.updatedAgents ?? []}
            sx={{
              minWidth: isSmallScreen ? "90%" : 175,
              maxWidth: isSmallScreen ? "90%" : 175,
              paddingRight: showAssign ? "20px" : "8px",
              borderRadius: 25,
            }}
            startAdornment={
              <InputAdornment position="start">
                <AccountCircleIcon
                  sx={{
                    color: "#DF6437",
                    fontSize: 30,
                  }}
                />
              </InputAdornment>
            }
            endAdornment={
              showAssign ? (
                <Tooltip title="Save">
                  <IconButton
                    onClick={updateAgentRoomAssignment}
                    color="secondary"
                  >
                    <PublishedWithChangesRoundedIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )
            }
            onChange={(e) => {
              handleAgentSelectChange(e.target.value);
            }}
            renderValue={(selected) => {
              const displayLimit = 2;
              const selectedNames = selected.map((userId) => {
                const agent = agents.find((agent) => agent.userId === userId);
                return agent
                  ? `${agent.accountInfo.firstName} ${agent.accountInfo.lastName}`
                  : "";
              });
              return (
                <span>
                  {selectedNames.slice(0, displayLimit).join(", ")}
                  {selectedNames.length > displayLimit
                    ? ` +${selectedNames.length - displayLimit} more`
                    : ""}
                </span>
              );
            }}
          >
            {agents.map((agent) => (
              <MenuItem value={agent.userId} key={agent.userId}>
                <Checkbox
                  checked={roomAgents[
                    conversation.documentId
                  ]?.updatedAgents.includes(agent.userId)}
                />
                <ListItemText
                  primary={`${agent.accountInfo.firstName} ${agent.accountInfo.lastName}`}
                />
              </MenuItem>
            ))}
          </Select>
          {/* {showAssign?<IconButton onClick={(e)=>{updateAgentRoomAssignment(conversation.documentId)}} color="secondary">
                            <PublishedWithChangesRoundedIcon/>
                    </IconButton>:
                    <>
                    </>
                    } */}

          <Select
            size="small"
            sx={{
              minWidth: isSmallScreen ? "90%" : 150,
              maxWidth: isSmallScreen ? "90%" : 150,
              borderRadius: 25,
              overflow: "hidden",
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  background: "#FFFFFF",
                },
              },
            }}
            value={conversation.status}
            renderValue={(selected) => {
              const status = statuses.find(
                (status) => status.value === selected
              );
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CircleIcon style={{ color: status.color, fontSize: 20 }} />
                  <span style={{ marginLeft: 8 }}>
                    {capitalizeWords(status.value)}
                  </span>
                </div>
              );
            }}
            onChange={(e) => {
              changeConversationStatus(conversation, e.target.value);
            }}
          >
            {statuses.map((status) => (
              <MenuItem value={status.value}>
                <ListItemIcon>
                  <CircleIcon style={{ color: status.color, fontSize: 20 }} />
                </ListItemIcon>
                <ListItemText primary={capitalizeWords(status.value)} />
              </MenuItem>
            ))}
          </Select>

          {!isSmallScreen ? (
            <IconButton onClick={handleInfoToggle} sx={{ mr: 1 }}>
              <MenuOpenRoundedIcon />
            </IconButton>
          ) : null}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ConversationHeader