import React,{useEffect, useState} from 'react'
import {useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { TailSpin } from "react-loader-spinner";
import {Box} from "@mui/material";
import { SERVER_BASE_URL } from "../../constants.js";

function AuthRedirect() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const exchangeToken = searchParams.get("token");
    const [loading, setLoading] = useState(true);
    const signIn = useSignIn();

    useEffect(() => {
        const authenticate = async () => {
            if (exchangeToken != null) {
                try {
                    const response = await axios.post(
                        `${SERVER_BASE_URL}/sharedInbox/auth/authExchange`,
                        {},
                        { headers: { exchangeToken: exchangeToken } }
                    );
                    if(response.status===200){
                        if(signIn({
                            auth:{
                              token:response.data?.authToken,
                              type:'Bearer',
                            },
                            userState: response.data?.userState,
                            isSignIn: true,
                            isUsingRefreshToken: false,
                        })){
                          navigate('/conversation?view=iframe')
                        }
                    }
                } catch (error) {
                    console.error('Error authenticating exchange token:', error);
                }
            }
        };
        authenticate();
    }, [exchangeToken]);
    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <TailSpin
                height="60"
                width="60"
                color="#FFE6CB"
            />
        </Box>
    )
}

export default AuthRedirect