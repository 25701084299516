import React,{useEffect, useState,useRef} from 'react';
import {useSearchParams } from "react-router-dom";

import NavigationSearchFilter from '../Common/NavigationSearchFilter.jsx';
import NotificationBar from '../Common/NotificationBar.jsx';
import ConversationCard from '../Common/ConversationCard.jsx';
import {Grid,Box, Typography,IconButton, Tabs, Tab} from '@mui/material';
import {useMediaQuery,useTheme} from '@mui/material';
import ConversationWindow from './ConversationWindow.jsx';
import { getAgents, getRooms,getEvents,getNotifications,deleteNotifications, updateConversationStatus, updateRoomAgentsAssignment, getParticipants,subscribeToFCMTopic} from '../../services/conversations.js';
import {useAppStore} from './AppStore.jsx';
import socket from "../../socket/index.js";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import LogoutButton from '../Common/LogoutButton.jsx';
import TabPanel,{a11yProps} from "../Common/TabPanel.jsx";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { CircularProgress } from '@mui/material';
import { messaging } from '../../services/firebase.js';
import { getToken , onMessage} from 'firebase/messaging';
import {SERVER_BASE_URL,FIREBASE_MESSAGING_VAPID_KEY} from '../../constants.js';
import sharedInboxIcon from '../../assets/sharedInboxIcon.png';
import notificationSound from '../../assets/messageNotification.mp3';

function AppBody({
  appView,
  headerHeight,
  showHeader,
  footerHeight,
  showFooter,
  setSocketStatus,
}) {

  const search = window.location.search;
  const [searchParams, setSearchParams] = useSearchParams();
  const paramConversationId = searchParams.get("conversationId");
  
  const [selectedWindow, setSelectedWindow] = useState("conversations");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const authUser = useAuthUser();
  const authHeader = useAuthHeader();
  const [user, setUser] = useState(authUser?.user ?? {});
  const [instanceId, setInstanceId] = useState(authUser?.instanceId ?? "");
  const [loading, setLoading] = useState(true);

  const [conversationsCount,setConversationsCount]=useState(0)
  const [conversations, setConversations] = useState([])
  
  const [agents, setAgents] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [currentConversation,setCurrentConversation]=useState({})

  const {eventsCount, events, updateEventsCount, updateEvents,notificationsCount,setNotificationsCount,notifications,setNotifications, appendEvent,roomAgents, setActualAgents , setUpdatedAgents}=useAppStore();

  const statuses = {
    active: 0,
    closed: 1,
  };

  const [tab, setTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const searchFilter = (value) => {
    setSearchQuery(value);
  };
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };
  const filterConversations = (conversations) => {
    if (!searchQuery) return conversations;
    const escapedQuery = escapeRegExp(searchQuery);
    const regex = new RegExp(escapedQuery, "i");
    return conversations.filter((conversation) =>
      regex.test(JSON.stringify(conversation))
    );
  };

  const handleSend = (documentId,message) => {
    const conversation = conversations.filter(
      (conv) => conv.documentId === documentId
    )[0];
    if (conversation) {
      const data = {
        instanceId: instanceId,
        conversation: {
          channel: conversation.channel,
          conversationId: conversation.conversationId,
          roomId: conversation.documentId,
        },
        participant: user,
        event: message,
      };
      socket.emit("conversation.agent.message", data);
    }
    return true;
  };

  const updateRoomAgents = async (documentId, data) => {
    const conversation = conversations.filter(
      (conv) => conv.documentId === documentId
    )[0];
    const response = await updateRoomAgentsAssignment({
      conversation: conversation,
      agents: data,
    });
    return response.status === 200 ? true : false;
  };

  const changeConversationStatus = async (conversation, status) => {
    if (conversation !== null && status !== null) {
      const response = await updateConversationStatus(conversation, status);
      if (response.status === 200) {
        if (status in statuses) {
          setTab(statuses[status]);
        }
        var conversationIndex = -1;
        setConversations((prevConversations) => {
          conversationIndex = prevConversations.findIndex(
            (conv) => conv.documentId === conversation.documentId
          );
          if (conversationIndex === -1) {
            return prevConversations;
          }
          var updatedConversations = [...prevConversations];

          updatedConversations[conversationIndex] = {
            ...updatedConversations[conversationIndex],
            status: status,
          };
          return updatedConversations;
        });
        return conversationIndex;
      }
    }
  };

  const concateChat = (response) => {
    var conversationIndex = -1;
    setConversations((prevConversations) => {
      var updatedConversations = [...prevConversations];
      conversationIndex = prevConversations.findIndex(
        (conv) => conv.documentId === response.documentId
      );
      if (conversationIndex === -1) {
        const updatedConversation = conversationAppend(
          response.documentId,
          response.conversation
        );
        setConversationsCount((prevCount)=>prevCount+1)
        return updatedConversation;
      }
      else{
        updatedConversations[conversationIndex].lastActivity = response.conversation?.lastActivity;
        updatedConversations[conversationIndex].timestamp = response.conversation?.timestamp;
        updatedConversations[conversationIndex].variables = response.conversation?.variables;
        updatedConversations[conversationIndex].status = response.conversation?.status;
      }
      const existingEventIndex = (events[response.documentId]??[]).findIndex((event) => event.documentId === response.event.documentId);
      if (existingEventIndex === -1) {
        updateEventsCount(response.documentId,eventsCount[response.documentId]??0+1)
        appendEvent(response.documentId,response.event)
      }
      return updatedConversations;
    });
    return conversationIndex;
  };

  const conversationMessageHandler = (response) => {
    const index = concateChat(response);
    if(index!==-1){
      var updatedConversations = [];
      setConversations((prevConversations) => {
        updatedConversations = [...prevConversations];
        updatedConversations.sort((a, b) => {
          const aTimestamp = new Date(a.timestamp || Date.now());
          const bTimestamp = new Date(b.timestamp || Date.now());
          return bTimestamp - aTimestamp;
        });
        return updatedConversations;
      })
    }
  };

  // const conversationAppend = (documentId, conversation) => {
  //   var conversationIndex = -1;
  //   var updatedConversations = [];
  //   setConversations((prevConversations) => {
  //     conversationIndex = prevConversations.findIndex(
  //       (conv) => conv.documentId === documentId
  //     );
  //     updatedConversations = prevConversations;
  //     if (conversationIndex === -1) {
  //       updatedConversations = [...prevConversations, conversation];
  //     } else {
  //       updatedConversations[conversationIndex] = {
  //         ...updatedConversations[conversationIndex],
  //         ...conversation,
  //       };
  //     }
  //     return updatedConversations;
  //   });
  //   return updatedConversations;
  // };


  const conversationAppend = (documentId, conversation) => {
    var updatedConversations = [];
    setConversations((prevConversations) => {
      const conversationIndex = prevConversations.findIndex(
        (conv) => conv.documentId === documentId
      );
      if (conversationIndex === -1) {
        updatedConversations = [...prevConversations, conversation];
      } else {
        updatedConversations = [
          ...prevConversations.slice(0, conversationIndex),
          { ...prevConversations[conversationIndex], ...conversation },
          ...prevConversations.slice(conversationIndex + 1),
        ];
      }
      updatedConversations.sort((a, b) => {
        const aTimestamp = new Date(a.timestamp || Date.now());
        const bTimestamp = new Date(b.timestamp || Date.now());
        return bTimestamp - aTimestamp;
      });
      return updatedConversations;
    });
    return updatedConversations;
  };
  
  

  useEffect(() => {
    socket.auth = { ...user, authHeader };
    socket.connect();
    const updateSocketStatus = (status) => {
      setSocketStatus(status);
    };
    const handleHumanAssistanceNeeded = (response) => {
      conversationAppend(response.documentId, response.conversation);
    };
    const handleConversationMessage = (response) => {
      conversationMessageHandler(response);
    };
    socket.on("connect", () => {
      updateSocketStatus("connected");
    });
    socket.on("reconnect_attempt", () => {
      updateSocketStatus("reconnecting");
    });
    socket.on("connect_error", () => {
      updateSocketStatus("connection_error");
    });
    socket.on("disconnect", () => {
      updateSocketStatus("disconnected");
    });
    socket.on("conversation.agent.message.ack",handleConversationMessage);
    socket.on("conversation.message", (e)=>{console.log("conversation.message");handleConversationMessage(e)});
    socket.on("conversation.human.assistance", handleHumanAssistanceNeeded);
    socket.connected ? updateSocketStatus("connected") : updateSocketStatus("disconnected");
    return () => {
      socket.off("connect", updateSocketStatus);
      socket.off("reconnect_attempt", updateSocketStatus);
      socket.off("connect_error", updateSocketStatus);
      socket.off("disconnect", updateSocketStatus);
      socket.off("conversation.agent.message.ack", updateSocketStatus);
      socket.on("conversation.message", updateSocketStatus);
      socket.off("conversation.human.assistance", updateSocketStatus);
    };
  }, [socket, authUser]);

  async function fetchInitialData() {
    try {
      const [roomsResponse, agentsResponse,notificationsResponse] = await Promise.all([
        getRooms(),
        getAgents(),
        getNotifications()
      ]);
      if (roomsResponse.status === 200 && agentsResponse.status === 200) {
        if(roomsResponse.data.rooms.length>0){
          setConversations(roomsResponse.data.rooms || []);
        }
        if(roomsResponse.data.count>conversationsCount){
          setConversationsCount(roomsResponse.data.count)
        }
        setAgents(agentsResponse.data || []);
      }
      if(notificationsResponse.status===200){
        setNotificationsCount(notificationsResponse.data?.notificationsCount??0)
        setNotifications(notificationsResponse.data?.notifications??{})
      }
    } 
    catch (error) {
      console.error("Error fetching initial data:", error);
    } 
    finally {
      setLoading(false);
    }
  }

  async function fetchRoomsData(startAfterDocument=null) {
    try {
      const response = await getRooms(null,startAfterDocument);
      if (response.status === 200) {
        if(response.data){
            if(response.data.rooms.length>0){
              const newData = response.data.rooms || [];
              setConversations(prevConversations => [
                ...prevConversations,
                ...newData
              ]);
            }
            if(response.data.count>conversationsCount){
              setConversationsCount(response.data.count)
            }
        }
      }
    } 
    catch (error) {
      console.error("Error fetching rooms data:", error);
    }
  }

  useEffect(() => {
    fetchInitialData();
    // const myInterval = setInterval(fetchRoomsData, 30000);
    // return () => {
    //   clearInterval(myInterval);
    // };
  }, []);

  const filteredConversations = filterConversations(conversations);


  const endOfActiveRef = useRef(null);
  const endOfClosedRef = useRef(null);

  const mobileNavigations = [
    {
      value:"conversations",
      icon: <QuestionAnswerOutlinedIcon/>,
      title: "Conversations",
    },
    // {
    //   value:'notifications',
    //   icon: <NotificationsNoneOutlinedIcon />,
    //   title: "Notifications",
    // },
    // {
    //   value:'profile',
    //   icon: <PermIdentityOutlinedIcon/>,
    //   title: "Profile",
    // },
  ];

  const fetchEvents = async (startAfterDocument=null) => {
    try {
        const response=await getEvents(selectedConversation, startAfterDocument);
        if(response.status===200){
          if(response.data?.events?.length>0){
            updateEvents(selectedConversation,response.data.events)
          }
          if(response.data.count){
            updateEventsCount(selectedConversation,response.data.count)
          }
        }
        
    } catch (error) {
      console.error('Error fetching rooms data:', error);
    }
  };

  const fetchParticipants = async () => {
    try {
        const response=await getParticipants(selectedConversation);
        if(response.status===200){
          if(response.data?.participants?.length>0){
            const values = agents.filter((agent) =>response.data?.participants?.some((participant) => participant.userId === agent.userId)).map((agent) => agent.userId);
            setActualAgents(selectedConversation, values);
          }
        }
    } catch (error) {
      console.error('Error fetching rooms data:', error);
    }
  };



  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && conversationsCount!==conversations.length) {
          const handleFetch = async () => {
            try {
              await fetchRoomsData(conversations[conversations.length - 1].documentId);
            } catch (error) {
              console.error('Error fetching rooms data:', error);
            }
          };

          handleFetch(); 
        }
      },
      { threshold: 1.0 }
    );

    if (endOfActiveRef.current) {
      observer.observe(endOfActiveRef.current);
    }

    if (endOfClosedRef.current) {
      observer.observe(endOfClosedRef.current);
    }

    return () => {
      if (endOfActiveRef.current) {
        observer.unobserve(endOfActiveRef.current);
      }

      if (endOfClosedRef.current) {
        observer.unobserve(endOfClosedRef.current);
      }
    };
  }, [conversations, fetchRoomsData]);

  useEffect(()=>{
    if(selectedConversation===null){
      setCurrentConversation({})
    }
    else{
      setCurrentConversation(conversations.find(
        (conv) => conv.documentId === selectedConversation
      ))
      fetchEvents(null)
      fetchParticipants()
      deleteNotifications(selectedConversation)
      
      const currentNotificationCount = notifications[selectedConversation] || 0;
      setNotificationsCount(prevCount => prevCount - currentNotificationCount);
      setNotifications(prevNotifications => {
        const updatedNotifications = {
          ...prevNotifications,
          [selectedConversation]: 0
        };
        return updatedNotifications;
      });
    }
  },[selectedConversation])
  
  const [fcmToken,setFCMToken]=useState('')
  const [audio] = useState(new Audio(notificationSound));

  function handleNotifcations(documentId){
    if(documentId===currentConversation?.documentId){
      deleteNotifications(documentId)
    }
    else if (documentId !== selectedConversation) {
      const currentNotificationCount = notifications[documentId] || 0;
      setNotificationsCount(prevCount => prevCount + 1);
      setNotifications(prevNotifications => {
        const updatedNotifications = {
          ...prevNotifications,
          [documentId]: (prevNotifications[documentId] || 0) + 1
        };
        return updatedNotifications;
      });
    }
  }

  onMessage(messaging, (payload) => {
    //console.log('Message received. ', payload);
    const {data}=payload
    const {documentId}=data
    conversationMessageHandler({...data,conversation:JSON.parse(data.conversation),event:JSON.parse(data.event)})
    if (Notification.permission === 'granted') {
      new Notification(payload.notification.title, { 
        body: payload.notification.body, 
        icon: sharedInboxIcon 
      });
      if(documentId!==selectedConversation){
        audio.currentTime = 0;
        audio.play().catch(error => {
          console.error('Audio playback failed:', error);
        });
      }
    }
    handleNotifcations(documentId)
  });



  useEffect(() => {
    const unlockAudio = () => {
      audio.play().catch(() => {});
      audio.pause()
      document.removeEventListener('click', unlockAudio);
    };

    document.addEventListener('click', unlockAudio);

    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'PLAY_SOUND') {
        audio.currentTime = 0;
        audio.play().catch(error => {
          console.error('Audio playback failed:', error);
        });
      }
      if (event.data && event.data.type === 'NEW_BACKGROUND_MESSAGE') {
        const data=event.data.data
        const {documentId}=data
        conversationMessageHandler({...data,conversation:JSON.parse(data.conversation),event:JSON.parse(data.event)})
      }
    });

    
    // Register service worker only if not already registered
    navigator.serviceWorker.register(`${SERVER_BASE_URL}/data/firebase-messaging-sw.js`)
      .then((registration) => {
        console.log('Service Worker registered');
        
        // Request notification permission
        Notification.requestPermission()
          .then((permission) => {
            if (permission === 'granted') {
              console.log('Notification permission granted.');
              getToken(messaging, { vapidKey: FIREBASE_MESSAGING_VAPID_KEY, serviceWorkerRegistration: registration })
                .then(async (currentToken) => {
                  if (currentToken) {
                    setFCMToken(currentToken)
                    await subscribeToFCMTopic({ fcmToken: currentToken });
                  } else {
                    console.log('No registration token available. Request permission to generate one.');
                  }
                })
                .catch((err) => {
                  console.log('An error occurred while retrieving token. ', err);
                });
            } else {
              console.log('Unable to get permission to notify.');
            }
          });
      })
      .catch((err) => {
        console.log('Service Worker registration failed:', err);
      });
  }, []);
   
  useEffect(()=>{
    if(conversations && conversations.length>0 && paramConversationId!==null){
      console.log("Setting Conversation Params")
      setSelectedConversation(paramConversationId)
      setSearchParams({})
    }
  },[conversations,paramConversationId])

  // window.addEventListener('focus', () => {
  //   fetchInitialData(); 
  // });

  return (
    <Grid
      container
      direction="row"
      spacing={0}
      sx={{
        flex: "auto",
        mt: `${showHeader ? headerHeight : 0}px`,
        mb: `${showFooter ? footerHeight : 0}px`,
      }}
    >
      {selectedWindow==="conversations" && (!isMobile || selectedConversation===null) &&
        <Grid
          item
          container
          direction="column"
          xs={12}
          sm={12}
          md={4}
          lg={3}
          xl={3}
          sx={{ 
            borderRight: "1px solid #F2F2F2" 
          }}
        >
          <Box
            sx={{
              width: "100%",
              position: "sticky",
              top: 0,
              borderTop: "1px solid #F2F2F2",
              borderBottom: "1px solid #F2F2F2",
            }}
          >
            <Grid container direction="column" rowSpacing={2} sx={{ p: 2 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <NotificationBar notificationsCount={notificationsCount} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <NavigationSearchFilter searchFilter={searchFilter} />
              </Grid>
            </Grid>
          </Box>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="fullWidth"
          >
            <Tab label="Active" {...a11yProps(0)} />
            <Tab label="Closed" {...a11yProps(1)} />
          </Tabs>

          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              overflow: "auto",
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                overflowY: "auto",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
            >
              {conversations.length > 0 ? (
                <React.Fragment>
                  <TabPanel value={tab} index={0}>
                    {filteredConversations.filter(
                      (conversation) => conversation.status === "active"
                    ).length > 0 ? (
                      <Grid container direction="column">
                        {filteredConversations
                          .filter(
                            (conversation) => conversation.status === "active"
                          )
                          .map((conversation, index) => (
                            <ConversationCard
                              key={index}
                              conversation={conversation}
                              unreadMessagesCount={notifications[conversation.documentId]??0}
                              selected={
                                selectedConversation === conversation.documentId
                              }
                              setSelectedConversation={setSelectedConversation}
                            />
                          ))}
                          <div ref={endOfActiveRef}>
                            {conversationsCount!==conversations.length && 
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  height: "100px",
                                }}
                              >
                                <CircularProgress variant="indeterminate" size={25} color='secondary' />
                              </Box>
                            }
                          </div>
                      </Grid>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Typography>No Active Conversations</Typography>
                      </Box>
                    )}
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    {filteredConversations.filter(
                      (conversation) => conversation.status === "closed"
                    ).length > 0 ? (
                      <Grid container direction="column">
                        {filteredConversations
                          .filter(
                            (conversation) => conversation.status === "closed"
                          )
                          .map((conversation, index) => (
                            <ConversationCard
                              key={index}
                              conversation={conversation}
                              unreadMessagesCount={notifications[conversation.documentId]??0}
                              selected={
                                conversation.documentId ===selectedConversation
                              }
                              setSelectedConversation={setSelectedConversation}
                            />
                          ))}
                          <div
                            ref={endOfClosedRef}
                          >
                            {conversationsCount!==conversations.length && 
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  height: "100px",
                                }}
                              >
                                <CircularProgress variant="indeterminate" size={25} color='secondary' />
                              </Box>
                            }   
                          </div>
                      </Grid>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Typography>No Closed Conversations</Typography>
                      </Box>
                    )}
                  </TabPanel>
                </React.Fragment>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Typography>No Conversations</Typography>
                </Box>
              )}
            </Box>
          </Box>
          {!isMobile && appView === "tab" ? (
              <Box
                sx={{
                  mt: "auto",
                  position: "sticky",
                  top: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LogoutButton fcmToken={fcmToken} />
              </Box>
            ) : null
          }
          {isMobile && selectedConversation===null && (
          <Box
            sx={{
              mt: "auto",
                  position: "sticky",
              pt: 1,
              pb: 1,
              display: "flex",
              bottom: 0,
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              borderRadius: "30px 30px 0px 0px",
              width: "100%",
              backgroundColor: "#F3F4F7",
            }}
          >
            {mobileNavigations.map((navItem) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => setSelectedWindow(navItem.value)}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  sx={{ color: selectedWindow === navItem.value && "#DF6437" }}
                >
                  {navItem.icon}
                </IconButton>
                <Typography
                  sx={{
                    fontSize:'10px',
                    //fontSize:"calc(8px + (14 - 8) * ((100vw - 400px) / (800 - 300)));",
                    color:selectedWindow === navItem.value ? "#DF6437" : "#848396",
                  }}
                >
                  {navItem.title}
                </Typography>
              </Box>
            ))}
          </Box>
      )}
        </Grid>
      }

      {selectedConversation!==null ?
        <Grid item container xs={12} sm={12} md={8} lg={9} xl={9}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Typography>Loading</Typography>
            </Box>
          ) : (
            <ConversationWindow
              user={user}
              agents={agents}
              setSelectedConversation={setSelectedConversation}
              conversation={currentConversation}
              handleSend={handleSend}
              eventsCount={eventsCount[selectedConversation]??0}
              events={events[selectedConversation]??[]}
              fetchEvents={fetchEvents}
              changeConversationStatus={changeConversationStatus}
              updateRoomAgents={updateRoomAgents}
              showHeader={showHeader}
              headerHeight={headerHeight}
              showFooter={showFooter}
              footerHeight={footerHeight}
            />
          )}
        </Grid>
        :
        <React.Fragment>
          {!isMobile &&
          <Grid item container xs={12} sm={12} md={8} lg={9} xl={9}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>No Conversation Selected</Typography>
            </Box>
          </Grid>
          }
        </React.Fragment>
      }
    </Grid>
  );
}

export default AppBody

