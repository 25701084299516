import { Grid,Box, Typography, IconButton,Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { convertISOToTimestamp, convertTimeDifferenceToSentence, convertUTCToTimestamp } from "../../utils/dateTimeFunctions";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import AssistantRoundedIcon from '@mui/icons-material/AssistantRounded';
import { uuidToBase64 } from "../../utils/stringFunctions";

function ConversationCard({
  selected,
  setSelectedConversation,
  conversation,
  unreadMessagesCount,
  setMobileConversationSelected,
}) {
  const [loading, setLoading] = useState(true);
  const [iconProps, setIconProps] = useState({
    icon: <AssistantRoundedIcon />,
    background: "#FFE6CB",
    color: "#075e54",
  });

  useEffect(() => {
    if (conversation && conversation.channel) {
      switch (conversation.channel) {
        case "widget":
          setIconProps({
            icon: <AssistantRoundedIcon sx={{ fontSize: 26 }} />,
            background: "#DFEBFA",
            color: "#6C7ED6",
          });
          setLoading(false);
          break;
        case "whatsapp":
          setIconProps({
            icon: <WhatsAppIcon sx={{ fontSize: 26 }} />,
            background: "#CEF1D3",
            color: "#075e54",
          });
          setLoading(false);
          break;
        case "sms":
          setIconProps({
            icon: <ForumRoundedIcon sx={{ fontSize: 26 }} />,
            background: "#B1DBFC",
            color: "#60B8FE",
          });
          break;
        default:
          setIconProps({
            icon: <AssistantRoundedIcon />,
            background: "#FFE6CB",
            color: "#075e54",
          });
          setLoading(false);
          break;
      }
    }
  }, [conversation]);

  return loading ? (
    null
  ) : (
    <Grid
      onClick={() => {
        setSelectedConversation(conversation.documentId);
        setMobileConversationSelected && setMobileConversationSelected(true);
      }}
      container
      direction="row"
      spacing={0}
      sx={{
        p: 1,
        minHeight: "80px",
        maxHeight: "100px",
        cursor: "pointer",
        background: selected ? "#F3F4F7" : "#FFFFFF",
        borderBottom: "1px solid #F2F2F2",
      }}
    >
      <Grid
        item
        xs={2.5}
        sm={2}
        md={2}
        lg={2}
        xl={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          disableFocusRipple
          disableRipple
          disableTouchRipple
          sx={{ background: iconProps.background, color: iconProps.color }}
        >
          {iconProps.icon}
        </IconButton>
      </Grid>
      <Grid
        item
        xs={7}
        sm={8}
        md={7}
        lg={7}
        xl={7}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "DM Sans Regular",
              fontWeight: 600,
              fontSize: "14px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {(() => {
              const firstName =
                conversation.variables?.customer?.firstName || "";
              const lastName = conversation.variables?.customer?.lastName || "";
              const customerName = `${firstName} ${lastName}`.trim();

              const profileName=conversation.variables?.customer?.profileName || "";
              if (customerName||profileName) {
                return customerName||profileName;
              } 

              if (conversation.channel.toLowerCase() === "widget") {
                return `${conversation.channel.toUpperCase()} ${uuidToBase64(
                  conversation.conversationId
                )}`;
              } else {
                return conversation.conversationId;
              }
            })()}
          </Typography>
          <Typography
            sx={{
              fontFamily: "DM Sans Regular",
              fontSize: "14px",
              fontWeight: 600,
              color: "#68677F",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {conversation?.lastActivity?.text ?? ""}
          </Typography>
          {conversation.variables?.humanAssistanceNeeded && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                mt: 1,
                mr: 2,
              }}
            >
              <Chip
                label="Human Assistance Needed"
                variant="contained"
                size="small"
                sx={{
                  maxHeight:"20px",
                  fontSize: "10px",
                  background: "#FFE6CB",
                  color: "#030229",
                }}
              />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={2.5}
        sm={2}
        md={3}
        lg={3}
        xl={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          {unreadMessagesCount>0 && 
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: 25,
                height: 25,
                borderRadius: "50%",
                background: "#FFE6CB",
                //background: "inherit",
                color: "#000000",
              }}
            >
              <Typography sx={{fontSize:'13px',fontWeight:600}}>{unreadMessagesCount > 10 ? `10+` :  `${unreadMessagesCount}`}</Typography>
            </Box>
          }
          
          <Typography
            sx={{
              fontSize: 12,
              color: "#323232",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {convertTimeDifferenceToSentence(
              convertUTCToTimestamp(conversation.lastActivity.timestamp)
            )}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ConversationCard;
