import React from 'react';
import ReactDOM from 'react-dom/client';
import {ThemeProvider} from "@mui/material";
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import { store} from "./auth/store.js";
import theme from "./theme.js";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </Provider>,
);

